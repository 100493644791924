<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    ref="clearInput"
  >
    <v-form
      v-if="columnType !== 'todo'"
      @submit.native.prevent="handleSubmit(submitPost)"
    >
      <div class="post-create">
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          mode="passive"
        >
          <v-row
            align="center"
          >
            <avatar
              :url="currentUser.photo_url"
              :avatar-type="columnType"
            />
            <v-textarea
              v-model="postText"
              clearable
              dense
              auto-grow
              counter
              solo
              class="post-text-field"
              append-icon="mdi-emoticon-cool-outline"
              :placeholder="placeholder(errors)"
              :rows="4"
              :error-messages="errors[0]"
              :outlined="focused"
              :background-color="backgroundColor(errors)"
              :color="inputStyle.outlineColor"
              @click:append="toggleIcon"
              @blur="changeFocus"
              @focus="changeFocus"
              @input="emitValue"
            />
          </v-row>
          <v-expand-transition>
            <div class="d-flex justify-center pt-10">
              <Picker
                v-if="emojiPickerSelected"
                :data="emojiIndex"
                :title="emojiTitle"
                emoji="the_horns"
                set="apple"
                @select="showEmoji"
              />
            </div>
          </v-expand-transition>
          <v-row
            class="post-send"
            align="center"
            justify="space-between"
          >
            <div class="post-check">
              <v-checkbox
                v-model="talk"
                class="no-background-hover"
                :ripple="false"
                :off-icon="inputStyle.iconChecked"
                :on-icon="inputStyle.iconUnchecked"
              />
              <span>
                {{ $t('views.retro.dont_wanna_talk') }}
              </span>
            </div>
            <send-post-btn
              :class="inputStyle.shadowButton"
              :button-color="inputStyle.buttonColor"
              type="submit"
            />
          </v-row>
        </ValidationProvider>
      </div>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import data from 'emoji-mart-vue-fast/data/all.json';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';

const emojiIndex = new EmojiIndex(data);

export default {
  name: 'PostInput',
  components: {
    SendPostBtn: () => import('@/components/globals/SendPostBtn.vue'),
    Avatar: () => import('@/components/globals/Avatar.vue'),
    Picker,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    columnType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      focused: false,
      emojiPickerSelected: false,
      talk: false,
      postText: '',
      postInput: {},
      emojiIndex,
      emojisOutput: '',
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),

    inputStyle() {
      return this.defineInputStyle();
    },

    emojiTitle() {
      return this.$t('components.posts_emojis.pick_your_emoji');
    },

  },
  methods: {
    ...mapActions([
      'createPost',
    ]),

    changeFocus() {
      this.focused = !this.focused;
    },

    toggleIcon() {
      this.emojiPickerSelected = !this.emojiPickerSelected;
    },

    emitValue(args) {
      this.$emit('input', args);
    },

    backgroundColor(errors) {
      if (this.focused) {
        return '#FFF';
      } if (errors.length > 0) {
        return 'error';
      }

      return 'textInputPost';
    },

    placeholder(errors) {
      if (errors.length > 0) {
        return 'Escreva algo antes de enviar';
      }

      return this.inputStyle.placeholder;
    },

    defineInputStyle() {
      if (this.columnType === 'improve') {
        this.postInput = {
          buttonColor: 'postImprove',
          iconChecked: 'checkbox-icon-improve',
          iconUnchecked: 'checkbox-icon-improve-check',
          outlineColor: 'postImprove',
          shadowButton: 'button-shadow-improve',
          placeholder: this.$t('views.retro.what_do_we_need_to_improve'),
        };
      } else if (this.columnType === 'good') {
        this.postInput = {
          buttonColor: 'postGood',
          iconChecked: 'checkbox-icon-good',
          iconUnchecked: 'checkbox-icon-good-check',
          outlineColor: 'postGood',
          shadowButton: 'button-shadow-good',
          placeholder: this.$t('views.retro.what_was_good_about_the_week'),
        };
      } else if (this.columnType === 'notice') {
        this.postInput = {
          buttonColor: 'postNotice',
          iconChecked: 'checkbox-icon-notice',
          iconUnchecked: 'checkbox-icon-notice-check',
          outlineColor: 'postNotice',
          shadowButton: 'button-shadow-good',
          placeholder: this.$t('views.retro.what_notice_you_have'),
        };
      }

      return this.postInput;
    },

    submitPost() {
      const payload = {
        text: this.postText,
        category: this.columnType,
        talk: this.talk,
      };

      this.createPost(payload);
      this.postText = '';
      this.talk = false;
      this.$refs.clearInput.reset();
    },

    showEmoji(emoji) {
      if (this.postText === null) {
        this.postText = this.emojisOutput + emoji.native;
      } else {
        this.postText += this.emojisOutput + emoji.native;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .post-create {
    margin: 30px 24px 0px 24px;

    .post-text-field ::v-deep {
      font-family: 'NunitoRegular';
      padding-left: 14px;
      border-radius: 8px;
      // max-width: fit-content;
      font-size: 16px;

      &:focus {
        outline: none;
      }

      .post-good:focus {
        box-shadow: 0px 3px 6px $shadow-green;
        border: 2px solid $post-good;
      }

      .post-improve:focus {
        box-shadow: 0px 3px 6px $shadow-orange;
        border: 2px solid $post-improve;
      }

      .v-text-field__details {
        display: none;
      }
    }

    .post-send {
      margin-bottom: 20px;
      font-family: 'MontserratMedium';
      font-size: 14px;

      .button-shadow-improve {
        box-shadow: 0px 6px 24px $shadow-orange;
      }

      .button-shadow-good {
        box-shadow: 0px 6px 24px $shadow-green;
      }

      .button-shadow-notice {
        box-shadow: 0px 6px 24px $shadow-green;
      }

      .post-check {
        display: inline-flex;
        align-items: center;
        padding-left: 8px;
      }

      .no-background-hover::before {
        background-color: transparent !important;
      }
    }
  }

  @media screen and (min-width: $md) {
    .post-create {
      margin: 20px 10px 0px 10px;

      .post-text-field ::v-deep {
        padding-left: 10px;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .post-create {
      margin: 20px 10px 0px 10px;

      .post-text-field ::v-deep {
        padding-left: 4px;
      }
    }
  }
</style>
